import config from "src/config";
import {
    BaseService
} from "./base.service";

export class ShipmentConverterService extends BaseService {
    baseUrl = `${config.apiUrl}/shipment/converter`;

    async bol2Shipment(file) {
        const formData = new FormData();
        formData.append("file", file);

        const response = await fetch(`${this.baseUrl}/bol`, this.header({
            method: 'POST',
            body: formData,
        }, true));

        const result = await response.json();
        if (!response.ok) throw new Error(`Failed to convert BOL to shipment: ${result.message}`);

        return result;
    }
}