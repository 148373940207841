import { Box, Button, FormControl, FormControlLabel, FormLabel, MenuItem, Switch, Tooltip, Typography } from "@mui/material";
import { PackagePOMapper } from "../PackageFields/PackagePOMapper";
import { Equipments } from "src/pages/shipments/constants/equipments";
import { BasicPONumberField } from "../PONumberField/BasicPONumberField";
import { LocationSearchField } from "../LocationSearchField/LocationsSearchField";
import { useEffect, useState } from "react";
import { FormInput } from "src/components/form/FormInput";
import { Controller, useFormContext } from "react-hook-form";
import { MuiFileInput } from "mui-file-input";
import { CloudUploadOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useShipmentConverterQueries } from "src/pages/shipments/hooks/useShipmentConverterQueries";
import { useShipmentFormContext } from "src/pages/shipments/hooks/useForm";

export const SpiersInitialForm = ({ onDone }) => {
    const [state, setState] = useState({
        isShownEquipment: false,
        isShownBOLInput: false,
        isShownPackages: false,
        isShownLocations: false,
        isValid: false,
        isExpedited: false,
        selectedBOL: null,
    });

    const { control, watch, getValues, setValue } = useFormContext();
    const { isConverting, convertBOLToShipment, shipment } = useShipmentConverterQueries();
    const { mapFields } = useShipmentFormContext();

    const mapEquipment = (selected) => {
        const equipments = getValues('equipment');
        const nonSelecteds = Object.keys(equipments).filter(eq => eq !== selected && eq !== 'selected');
        nonSelecteds.forEach(key => setValue(`equipment.${key}`, false));
        setValue(`equipment.${selected}`, true);
    }

    useEffect(() => {
        const mode = getValues('mode');
        const equipment = getValues('equipment.selected');
        const poNumber = getValues('poNumber');
        const items = getValues('items');
        const expedited = getValues('services.expedited');

        if (!!equipment) mapEquipment(equipment);

        const isShownEquipment = !!mode && mode !== 'LTL';
        const isShownBOLInput = !!equipment || (!!mode && mode === 'LTL');
        const isShownPackages = !!(poNumber && poNumber.length);
        const isShownLocations = !!(items && items.length);
        const isExpedited = expedited;

        if (expedited) {
            setValue('mode', 'TL')
        }

        setState({
            ...state,
            isShownEquipment,
            isShownBOLInput,
            isShownPackages,
            isShownLocations,
            isValid: !!state.selectedBOL,
            isExpedited,
        });
    }, [
        watch('mode'),
        watch('equipment.selected'),
        watch('poNumber'),
        watch('items'),
        watch('services.expedited')
    ]);

    const onBOLSelected = (file) => {
        setState({ ...state, selectedBOL: file, isValid: true });
    }

    useEffect(() => {
        if (shipment) {
            mapFields(shipment);
            onDone();
        }

    }, [shipment]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                borderRadius: "32",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    mb: "2rem",
                }}
            >
                <Typography
                    fontWeight={600}
                    variant="h1"
                    sx={{ "&.MuiTypography-h1": { fontSize: "1.5rem" } }}
                >
                    Let's create your shipment
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    mb: "1rem",
                }}
            >
                <Controller
                    name={`services.hazardousCargoCharge`}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Switch {...field} checked={!!field.value} />}
                            label={"This is a hazardous cargo charge"}
                        />
                    )}
                />

                <Controller
                    name={`services.expedited`}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Switch {...field} checked={!!field.value} />}
                            label={"This is a expedited shipment"}
                        />
                    )}
                />


                <FormControl sx={{ gap: '0.5rem' }}>
                    <FormLabel>Which is the mode for this shipment?</FormLabel>
                    <FormInput
                        label={'Mode'}
                        name={'mode'}
                        select
                        disabled={state.isExpedited}
                    >
                        {['TL', 'LTL'].map((mode) => (
                            <MenuItem key={mode} value={mode}>
                                {mode}
                            </MenuItem>
                        ))}
                    </FormInput>
                </FormControl>
                {state.isShownEquipment && (
                    <FormControl sx={{ gap: '0.5rem' }}>
                        <FormLabel>Select the equipment to be used</FormLabel>
                        <FormInput
                            label={'Equipment'}
                            name={'equipment.selected'}
                            select
                        >
                            {Equipments.map((eq) => (<MenuItem key={eq.key} value={eq.key}>{eq.label}</MenuItem>))}
                        </FormInput>
                    </FormControl>
                )}
                {state.isShownBOLInput && <UploadBOLInput onSelected={onBOLSelected} />}
                <Box>
                    <LoadingButton
                        variant="contained"
                        type="button"
                        onClick={() => convertBOLToShipment({ file: state.selectedBOL })}
                        fullWidth
                        disabled={!state.isValid}
                        loading={isConverting}
                        sx={{ height: "2.5rem", mt: "2rem", mb: "1rem" }}
                    >
                        Done
                    </LoadingButton>
                </Box>
            </Box>
        </Box>
    );
}


const UploadBOLInput = ({ onSelected }) => {
    const [file, setFile] = useState(null)

    const handleChange = (event) => {
        const file = event.target.files[0];
        setFile(file);
        if (file) {
            onSelected(file);
        }
    }

    return <FormControl>
        <FormLabel>Select the BOL to create the shipment</FormLabel>
        <Box display="flex" alignItems="center" gap={2}>
            <Button
                sx={{
                    display: "flex",
                    width: "13rem",
                    px: 1,
                    gap: 2,
                    borderRadius: "0.5rem",
                    fontWeight: 800,
                    boxShadow: 1,
                }}
                component="label"
            >
                <CloudUploadOutlined />
                {file ? 'Change BOL' : 'Upload BOL'}
                <input
                    accept=".jpg,.jpeg"
                    type="file"
                    hidden
                    onChange={handleChange}
                />
            </Button>
            {file && <Typography variant="body1">{file.name}</Typography>}
        </Box>
    </FormControl>
}