import {  Select } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export function FormSelect({ label, name, defaultValue, value, onChange, children, ...props }) {
  const { control } = useFormContext();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Controller
    control={control}
    name={name}
    sx={{ width: "100%" }}
    render={({ field }) => {
      const { onChange: innerOnChange } = field
      return (
        <Select
          multiple
          {...field}
          label={label}
          fullWidth
          defaultValue={defaultValue}
          value={value}
          onChange={(event) => {            
            innerOnChange(event)
            if(onChange) onChange(event)
          }}
          sx={{ width: "100%" }}
          MenuProps={MenuProps}          
        >
          {children}
        </Select>
      );
    }}
  />
  );
}