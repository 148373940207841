import { createContext, useEffect, useState } from "react"
import { useAuth } from "src/hooks/useAuth";
import { useChangeNoteQueries } from "src/pages/support/hooks/useChangeNoteQueries";
import { getSocketConnection } from "src/sockets/socketConnection";

export const SystemContext = createContext();

export const SystemProvider = ({ children }) => {
    const { session } = useAuth();
    const { getLastChangeNotes, lastChangeNotes } = useChangeNoteQueries();
    const [ socket, setSocket ] = useState(null);

    useEffect(() => {
        if (!!session?.token) {
            getLastChangeNotes()

            // socket init
            const newSocket = getSocketConnection();
            setSocket(newSocket);
        };
    }, [session]);

    return (
        <SystemContext.Provider
            value={{
                notes: lastChangeNotes,
                socket,
            }}
        >
            {children}
        </SystemContext.Provider>
    );
}