import {
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Typography,
  CardHeader,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormInput } from "src/components/form/FormInput";
import { ChangeNoteFormProvider } from "../../context/ChangeNoteFormContext";
import { useChangeNoteQueries } from "../../hooks/useChangeNoteQueries";
import { LoadingButton } from "@mui/lab";
import { MdEditor } from "md-editor-rt";
import { Controller, useFormContext } from "react-hook-form";
import "md-editor-rt/lib/style.css";
import { FormSelect } from "src/components/form/FormSelect";
import { SelectTenant } from "./SelectTenant";
import { SelectRole } from "./SelectRole";

export function ChangeNoteForm(props) {
  const { create, isCreating, update, isUpdating } = useChangeNoteQueries();
  const changeNote = props?.changeNote?.changeNote;
  const [role, setRole] = useState(changeNote?.roles ?? []);

  return (
    <ChangeNoteFormProvider
      onSave={!props.changeNote ? create : update}
      reset={props.reset ? props.reset : false}
      onClose={props.onClose}
      useFormParams={{ defaultValues: props.changeNote }}
    >
      <Box sx={{ maxWidth: "40%", margin: "auto", py: 3 }}>
        <Box
          sx={{
            width: "100%",
            display: {
              xs: "block",
              sm: "flex",
            },
            alignItems: "center",
            justifyContent: "space-between",
            mb: "2rem",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", pb: 2 }}>
            <Typography
              fontWeight={600}
              variant="h1"
              sx={{ "&.MuiTypography-h1": { fontSize: "1.5rem" } }}
            >
              {"Add release note"}
            </Typography>
          </Box>
        </Box>
        <FormInput
          label="Title"
          name={`changeNote.title`}
          type="text"
          required
          fullWidth
        />
        <Box height="12px" />
        <FormInput
          label="Subtitle"
          name={`changeNote.subtitle`}
          type="text"
          required
          fullWidth
        />
        <Box height="12px" />
        <MarkDownEditor />
        <Box height="12px" />
        <Box>
          <Controller
            name="changeNote.showForUsers"
            render={({ field }) => (
              <FormControlLabel
                sx={{ justifyContent: "flex-start", width: "100%" }}
                control={<Checkbox {...field} checked={field?.value} />}
                label={`Display changes when starting the app`}
              />
            )}
          />

          <Card
            variant="outlined"
            sx={{
              py: "1.5rem",
              px: "1rem",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "0.75rem",
              mb: "0.75rem",
              backgroundColor: "transparent",
            }}
          >
            <CardHeader
              title={"Select the Role and Tenant of the users who will see"}
              sx={{ "&.MuiCardHeader-root": { p: 0 } }}
            />
            <SelectRole defaultValue={changeNote?.roles ?? []} />
            <SelectTenant defaultValue={changeNote?.tenants ?? []} />
          </Card>
        </Box>

        <LoadingButton
          variant="contained"
          type="submit"
          loading={!props.changeNote ? isCreating : isUpdating}
          fullWidth
        >
          {!props.changeNote ? "Create" : "Update"}
        </LoadingButton>
      </Box >
    </ChangeNoteFormProvider>
  );
}

function MarkDownEditor() {
  const { setValue, watch } = useFormContext();
  const content = watch("changeNote.content");

  return (
    <MdEditor
      style={{
        backgroundColor: "transparent",
        color: "black",
        minHeight: "450px",
        maxHeight: "600px",
        overflowY: "auto",
      }}
      noUploadImg
      modelValue={content || ""}
      onChange={(value) => setValue("changeNote.content", value)}
      language="en-US"
    />
  );
}

ChangeNoteForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
