import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { ScheduleService } from "src/api/schedule.service";
import { Calendar } from "src/components/calendar";
import { Schedule } from "src/models/schedule";
import { Box, LinearProgress } from "@mui/material";
import { useAuth } from "src/hooks/useAuth";
import { ShipmentService } from "src/api/shipment.service";
import { CalendarFilters } from "./components/CalendarFilter";

import HouseIcom from "@mui/icons-material/House";
import { Apartment, Tag } from "@mui/icons-material";
import DialogLastChange from "../shipments/components/DialogLastChanges";
import { useNavigate } from "react-router-dom";

export function ShipmentsCalendarPage() {
  // CONSTRACTOR & WAREHOUSE MANAGER
  const { session } = useAuth();
  const service = new ScheduleService(session.token, session.tenant._id);
  const shipmentService = new ShipmentService(
    session.token,
    session.tenant._id
  );

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [refreshCalendar, setRefreshCalendar] = useState(false);

  // State to store selected filter options
  const [selectedFilters, setSelectedFilters] = useState({
    warehouses: [],
    locations: [],
    companies: [],
    status: [],
  });

  useEffect(() => {
    setIsLoading(true);
    async function fetchData() {
      let formattedEvents = [];
      let formattedShipments = [];

      try {
        // Load all the schedules
        const schedules = await service.getAll();

        // Set schedule title for each schedule object in schedules
        schedules?.forEach((schedule) => {
          schedule.title = session.permissions.IsContractor
            ? schedule.warehouse.name
            : schedule.company;
        });

        formattedEvents = schedules.map(Schedule.event);

        // Load all the shipments for the current user
        if (session.permissions.IsWarehouseManager) {
          const inbound = await shipmentService.getShipments("inbound");
          inbound.forEach((shipment) => {
            shipment.isInbound = true;
          });

          const outbound = await shipmentService.getShipments("outbound");
          outbound.forEach((shipment) => {
            shipment.isInbound = false;
          });

          formattedShipments = [...inbound, ...outbound].map(Schedule.shipment);
        }
      } finally {
        setEvents([...formattedEvents, ...formattedShipments]);
      }
    }

    fetchData().finally(() => setIsLoading(false));
  }, [refreshCalendar]);

  const handleRefreshCalendar = () => {
    setRefreshCalendar(!refreshCalendar);
  };

  // Extract unique filter options and format data
  const filterData = useMemo(() => {
    const uniqueValues = {
      warehouses: new Set(),
      warehousesKeys: new Set(),
      companies: new Set(),
      status: new Set(), // Assuming you have a 'status' field
    };

    events.forEach((event) => {
      const data = event.extendedProps;
      if (
        data.warehouse?.name &&
        !uniqueValues.warehousesKeys.has(data.warehouse?.name)
      ) {
        uniqueValues.warehouses.add({
          name: data.warehouse.name,
          location: data.warehouse.location,
        });
        uniqueValues.warehousesKeys.add(data.warehouse.name);
      }
      if (data.company) uniqueValues.companies.add(data.company);
      if (data.status) uniqueValues.status.add(data.status);
    });

    const filter = {
      warehouses: {
        key: "warehouse.name",
        options: Array.from(uniqueValues.warehouses).map((warehouse) => ({
          label: warehouse.name,
          location: warehouse.location,
        })),
        icon: <HouseIcom sx={{ width: "24px", opacity: 0.6 }} />,
      },
      companies: {
        key: "company",
        options: Array.from(uniqueValues.companies).map((company) => ({
          label: company,
        })),
        icon: <Apartment sx={{ width: "24px", opacity: 0.6 }} />,
      },
      // UNCOMMENT TO USE status FILTER
      // status: {
      //   key: "status",
      //   options: Array.from(uniqueValues.status).map((status) => ({
      //     label: status,
      //   })),
      //   icon: <Tag sx={{ width: "24px", opacity: 0.6 }} />,
      // },
    };

    return filter;
  }, [events]);

  // Function to handle filter changes
  const handleFilterChange = (filterType, value) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterType]: value,
    }));
  };

  // Filter events based on selected filters
  const filteredEvents = useMemo(() => {
    return events.filter((event, index) => {
      const data = event.extendedProps;
      const warehouses = selectedFilters.warehouses.map((i) => i.label);
      const companies = selectedFilters.companies.map((i) => i.label);
      const status = selectedFilters.status.map((i) => i.label);

      const isWarehouseFilterNotSelected = !selectedFilters.warehouses.length;
      const isWarehousesEmpty = warehouses.includes("Not set") && (!data.warehouse?.name ?? false);
      const isWarehouseValid = data.warehouse && selectedFilters.warehouses.map((i) => i.label).includes(data.warehouse.name);
      const matchWarehouses = isWarehouseFilterNotSelected || isWarehousesEmpty || isWarehouseValid;

      const isCompanyFilterNotSelected = !selectedFilters.companies.length;
      const isCompaniesEmpty = companies.includes("Not set") && !data.company;
      const isCompanyValid = selectedFilters.companies.map((i) => i.label).includes(data.company);
      const matchCompanies = isCompanyFilterNotSelected || isCompaniesEmpty || isCompanyValid;

      const isStatusFilterNotSelected = !selectedFilters.status.length;
      const isStatusEmpty = status.includes("Not set") && !data.status;
      const isStatusValid = selectedFilters.status.map((i) => i.label).includes(data.status);
      const matchStatus = isStatusFilterNotSelected || isStatusEmpty || isStatusValid;

      return matchWarehouses && matchCompanies && matchStatus;
    });
  }, [events, selectedFilters]);

  return (
    <>
      <Helmet>Shipments | Calendar</Helmet>
      <CalendarFilters
        options={filterData}
        onFilterChange={handleFilterChange}
      />
      {isLoading && (<Box sx={{ width: "100%", px: 3 }}> <LinearProgress /> </Box>)}
      <Calendar
        events={filteredEvents} // Pass filtered events to Calendar
        handleRefreshCalendar={handleRefreshCalendar}
        isLoading={isLoading}
      />
      <DialogLastChange
        userName={session.user.firstname}
        onDone={() => {
          navigate("/support")
        }}
      />
    </>
  );
}
