import { RequestQuoteOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, CardContent, CardHeader, Grid, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "src/hooks/useAuth";
import { fCurrency } from "src/utils/formatNumber";
import { useShipmentDetails } from "../../../context/ShipmentDetailsContext";
import { valueGetter } from "./DetailsDrawer";

export function QuotationsDetails() {
  const { quotations, shipment, getShipmentQuotations, isShipmentQuotationLoading } = useShipmentDetails();
  const [ acceptedQuotation, setAcceptedQuotation ] = useState(null);

  useEffect(() => {
    if (!quotations.length) getShipmentQuotations({ shipmentId: shipment._id });

    quotations.map((quotation) => {
      if (quotation.accepted) setAcceptedQuotation(quotation);
    })
	}, [quotations])

  return (
    <Box>
      { isShipmentQuotationLoading && <LinearProgress /> }
      <Grid container spacing={'1rem'}>
        { quotations.map((quotation, index) => <>
          <Grid item md={4}>
            <CarrierQuotation 
              acceptedQuotation={acceptedQuotation}
              key={quotation.quoteId} 
              quotation={quotation} 
              quotationIndex={index} />
          </Grid>
        </>
        )}
      </Grid>
    </Box>
  );
}

function CarrierQuotation({ quotation, acceptedQuotation, quotationIndex }) {
  const {
    shipment,
    confirmShipment,
    isConfirmationLoading,
    selectedQuotation,
    setSelectedQuotation,
  } = useShipmentDetails();

  const { session } = useAuth();

  const isBackOffice = session.permissions.IsBackOffice;
  const [isThisQuotationConfirmationLoading, setThisQuotationLoading] =
    useState(false);

  function handleShipmentConfirmation(quotation) {
    confirmShipment(shipment._id, quotationIndex);
    setSelectedQuotation(quotation);
    setThisQuotationLoading(true);
  }

  const isSelectedQuotation = (quotation) =>
    selectedQuotation?.quoteId == quotation.quoteId;

  function isCarrierBlocked(carrierName) {
    const blockedCarriers = [];
    return blockedCarriers.includes(carrierName);
  }

  useEffect(() => {
    if (acceptedQuotation) setSelectedQuotation(acceptedQuotation);
  }, [acceptedQuotation]);

  return (
    <Card
      variant="outlined"
      sx={{
        py: "0.5rem",
        px: "1rem",
        height: '100%',
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
      }}
    >
      <CardHeader
        sx={{
          "&.MuiCardHeader-root": { p: 0 },
          flexDirection: {
            xs: "column",
            md: "column",
            lg: "row",
          },
          justifyContent: "center",
          alignItems: "baseline",
          gap: "1rem",
        }}
        title={quotation.carrierName}
        subheader={"Carrier"}
      />
      <CardContent
        sx={{
          "&.MuiCardContent-root": { p: 0 },
          display: "flex",
          flexDirection: "column",
          gap: 2,
          // justifyContent: "space-between",
          // alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>Transit Time</Typography>
          <Typography fontWeight={600} fontSize={"1.2rem"}>
            {quotation.transitTime}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>Net Price</Typography>
          <Typography fontWeight={600} fontSize={"1.2rem"}>
            {fCurrency(quotation.netPrice)}
          </Typography>
        </Box>
        {isBackOffice && (
          <CarrierNote quotation={quotation} />
        )}
        {
          <LoadingButton
            loading={
              isConfirmationLoading && isThisQuotationConfirmationLoading
            }
            loadingPosition="start"
            startIcon={<RequestQuoteOutlined />}
            onClick={() => handleShipmentConfirmation(quotation)}
            disabled={
              (!!selectedQuotation && shipment.integrated) ||
              quotation.Blocked
            }
            className={isSelectedQuotation(quotation) ? "selected" : ""}
            variant="text"
            color={
              !!selectedQuotation &&
                shipment.integrated &&
                isSelectedQuotation(quotation)
                ? "success"
                : "primary"
            }
            sx={{
              "&.Mui-disabled.selected": {
                color: (theme) => theme.palette.success.darker,
                border: (theme) => theme.palette.success.darker,
                opacity: 0.8,
              },
              fontSize: "1rem",
              display: "flex",
              width: "100%",
            }}
          >
            {quotation.Blocked
              ? "Blocked Carrier"
              : "Confirm Shipment Quotation"}
          </LoadingButton>
        }
      </CardContent>
    </Card>
  );
}

const CarrierNote = ({ quotation }) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => setShowFullText(!showFullText);

  return (
    <>
      {quotation.carrierNote && (
        <Box>
          <Typography fontWeight={600}>Carrier Note</Typography>
          <div
            dangerouslySetInnerHTML={{
              __html: showFullText
                ? quotation.carrierNote
                : `${quotation.carrierNote.substring(0, 50)}`,
            }}
          ></div>
          {quotation?.carrierNote?.length > 50 && (
            <Button onClick={toggleText} size="small">
              {showFullText ? "See Less" : "See More..."}
            </Button>
          )}
        </Box>
      )}
    </>
  );
};