import { Box, Card, Typography, Button } from "@mui/material";
import React from "react";

export function CardAlternativeCity(props) {
    return <Card onClick={() => props.onClick()} sx={{marginBottom: "32px", marginTop: "24px"}}>
        <Button sx={{ width: "100%", padding: "16px", color: "black" }}>
            <Box paddingRight={"20px"} paddingTop={"2px"}>
                {props.leading}
            </Box>
            <Box display={"flex"} flexDirection={"column"} justifyContent={"start"} alignItems={"start"}>
                <Typography fontWeight={"bold"}>{props.title}</Typography>
                <Typography fontSize={"14px"}>{props.subtitle}</Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            {props.trailing}
        </Button>
    </Card>
}