export const CityColums = [
    {
        field: 'city',
        headerName: 'City',
        minWidth: 150,
    },
    {
        field: 'state',
        headerName: 'State',
        minWidth: 150,
    },
    {
        field: 'ctry',
        headerName: 'Country',
        minWidth: 150,
    },
    // {
    //     field: 'contacts',
    //     headerName: 'Contacts',
    //     minWidth: 150,
    //     renderCell: (params) => {
    //         const contacts = params.value;            
    //         return contacts.map(contact => `${contact.name}`).join(', ')
    //     }
    // }
]

export const CityDetailComuns = [
    {
        key: 'name',
        label: 'Name',
    },
    {
        key: 'addr1',
        label: 'Address 1',
    },
    {
        key: 'addr2',
        label: 'Address 2'
    },
    {
        key: 'city',
        label: 'City'
    },
    {
        key: 'state',
        label: 'State'
    },
    {
        key: 'zip',
        label: 'ZIP'
    }
]