import { postLogin } from "src/api";
import { types } from "./types";
import authReducer from "./AuthReducer";
import { Auth } from "../../models/roles";
import { AuthActions } from "../../models/actions";
import { UserService } from "src/api/user.service";

const USER_SESSION_LOCAL_STORAGE_KEY = 'user-session';
const USER_FILTERS_SESSION_STORAGE = 'datagridFilters';

const { createContext, useReducer, useEffect } = require("react");

export const AuthContext = createContext();

const init = () => {
  let userSession = JSON.parse(localStorage.getItem(USER_SESSION_LOCAL_STORAGE_KEY));
  if (userSession) {
    userSession.permissions = Auth(userSession);
    userSession.actions = AuthActions(userSession);
  }
  return {
    isLogged: !!userSession,
    session: userSession,
  };
};

export const AuthProvider = ({ children }) => {
  const [authState, dispatch] = useReducer(authReducer, {}, init);

  useEffect(() => { 
    reloadUserData() 
  },[]);

  // Actions Methods
  const login = async (credentials) => {
    try {
      const loginResponse = await postLogin(credentials);

      if (loginResponse.status === 200) {
        localStorage.setItem(USER_SESSION_LOCAL_STORAGE_KEY, JSON.stringify(loginResponse.value));
        // Wait for localStorage to be set
        const intervalId = setInterval(() => {
          if (localStorage.getItem(USER_SESSION_LOCAL_STORAGE_KEY)) {
            clearInterval(intervalId);

            // Dispatch action
            const action = { type: types.login, payload: loginResponse.value };
            dispatch(action);
          }
        }, 100);
      } else {
        const action = {
          type: types.loginFailure,
          payload: {
            internalCode: loginResponse.status,
            message: "username and/or password are invalid",
          },
        };
        dispatch(action);
      }
    } catch (error) {
      const action = {
        type: types.loginFailure,
        payload: error,
      };
      dispatch(action);
    }
  };

  const logout = () => {
    localStorage.removeItem(USER_SESSION_LOCAL_STORAGE_KEY);
    sessionStorage.removeItem(USER_FILTERS_SESSION_STORAGE);
    const action = { type: types.logout };
    dispatch(action);
  };

  const reloadUserData = async () => {
    const userSession = authState.session;

    if (userSession) {
      const service = new UserService(userSession.token, userSession.tenant._id);

      try {
        const userResponse = await service.getUserByToken();

        if (userResponse) {
          const updatedSession = { ...userSession, ...userResponse };
          localStorage.setItem(USER_SESSION_LOCAL_STORAGE_KEY, JSON.stringify(updatedSession));
          dispatch({ type: types.login, payload: updatedSession });
        } else {
          localStorage.removeItem(USER_SESSION_LOCAL_STORAGE_KEY);
          dispatch({ type: types.logout });
        }
      } catch (error) {
        console.error("Erro ao atualizar os dados do usuário:", error);
        localStorage.removeItem(USER_SESSION_LOCAL_STORAGE_KEY);
        dispatch({ type: types.logout });
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...authState,

        // Methods
        login,
        logout,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
