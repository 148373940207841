import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { CityFormProvider } from "../context/CityFormContext";
import { FormInput } from "src/components/form/FormInput";
import { LoadingButton } from "@mui/lab";
import { Clear } from "@mui/icons-material";
import { InputStateField } from "../components/StateField";
import { Container, Draggable } from "react-smooth-dnd";
import { useCityFormContext } from "../hooks/useCityFormContext";
import { countries } from "src/pages/shipments/constants/countries";

export function CityForm({ useFormParams, onSave, reset, ...props }) {
  const isSubmissionLoading = props.isSubmissionLoading;

  return (
    <CityFormProvider
      useFormParams={useFormParams}
      onSave={onSave}
      reset={reset}
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
    >
      <Card
        variant="outlined"
        sx={{
          py: "1.5rem",
          px: "1rem",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0.75rem",
        }}
      >
        <CardHeader
          title={"Basic Info"}
          sx={{ "&.MuiCardHeader-root": { p: 0 } }}
        />
        <CardContent
          sx={{
            "&.MuiCardContent-root": { p: 0, py: "1rem" },
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "0.75rem",
          }}
        >
          <Stack spacing={2}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <FormInput
                name={"city"}
                label="City"
                type="text"
                required
                sx={{ flexGrow: 1 }}
              />
              <InputStateField />
              <FormInput
                name={`ctry`}
                label="Country"
                select
                required
                sx={{ flexGrow: 1 }}
                defaultValue={countries[0].acronym}
              >
                {countries.map((ctry) => {
                  return (
                    <MenuItem key={ctry.acronym} value={ctry.acronym}>
                      {ctry.name}
                    </MenuItem>
                  );
                })}
              </FormInput>
            </Box>
          </Stack>
        </CardContent>
      </Card>

      <AlternativeCities />

      <LoadingButton
        variant="contained"
        fullWidth
        type="submit"
        loading={isSubmissionLoading}
        sx={{ py: "0.8rem", mt: "1rem" }}
      >
        Save City and Alternative Cities
      </LoadingButton>
    </CityFormProvider>
  );
}

function AlternativeCities() {
  const { 
    fieldsAlternativeCity, 
    removeAlternativeCity, 
    appendAlternativeCity, 
    moveAlternativeCity 
  } = useCityFormContext();
  // const { getValues, watch } = useFormContext();

  const onDragEnd = ({ removedIndex, addedIndex }) => {
    moveAlternativeCity(removedIndex, addedIndex);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        marginTop: 2,
      }}
    >
      <Typography component="h4" sx={{ fontWeight: 'bold' }}>
        Alternative Cities
      </Typography>
      <Container
        dragHandleSelector=".drag-handle"
        lockAxis="y"
        onDrop={onDragEnd}
      >
        {fieldsAlternativeCity.map((alternative_city, index) => {
          return (
            <Draggable key={alternative_city._id}>
              <div className="drag-handle" style={{ margin: 5 }}>
                <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
                  <FormInput
                    name={`alternative_cities[${index}].city`}
                    label="City"
                    required
                    type="text"
                    sx={{ flexGrow: 1 }}
                  />
                  <InputStateField 
                    prefix={`alternative_cities[${index}]`}
                    name={`alternative_cities[${index}].state`}
                  />
                  <FormInput
                    name={`alternative_cities[${index}].ctry`}
                    label="Country"
                    select
                    required
                    sx={{ flexGrow: 1 }}
                    defaultValue={countries[0].acronym}
                  >
                    {countries.map((ctry) => {
                      return (
                        <MenuItem key={ctry.acronym} value={ctry.acronym}>
                          {ctry.name}
                        </MenuItem>
                      );
                    })}
                  </FormInput>
                  <Button
                    sx={{ ml: 0, mr: 0 }}
                    onClick={() => removeAlternativeCity(index)}
                  >
                    <Clear />
                  </Button>
                </Box>
              </div>
            </Draggable>
          );
        })}
      </Container>
      <Button
        size="small"
        variant="text"
        align="left"
        onClick={() => appendAlternativeCity({ city: "", state: "" })}
      >
        + Add New Alternative City
      </Button>
    </Box>
  );
}
