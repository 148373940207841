import { ChevronRight } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";

export function CityDetails({ city, ...props }) {
  return (
    <Card
      variant="contained"
      sx={{
        py: "0.5rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.75rem",
        border: 1,
        borderColor: "grey.300",
        borderRadius: "4px",
      }}
    >
      <CardHeader
        title={
          <Typography sx={{ fontSize: '1rem', fontWeight: 'bold'}}>
            {"Basic Info"}
          </Typography>
        }
        sx={{
          "&.MuiCardHeader-root": {
            p: 0,
            ml: "2%"
          },
        }}
      />
      <Divider />
      <CardContent
        sx={{
          "&.MuiCardContent-root": {
            p: 0,
            ml: "2%"
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'column', xl: 'row' }, justifyContent: 'space-between' }}>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: { xs: 'column', lg: 'column', xl: 'row' }, justifyContent: 'space-between' }}>
            <FieldLine field={"City"} value={city.city} />
            <FieldLine field={"State"} value={city.state} />
            <FieldLine field={"Country"} value={city.ctry} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export function FieldLine({ field, value }) {
  return (
    <Box
      sx={{
        display: {
          md: "flex",
          xs: "block",
        },
        py: "0.25rem",
        gap: "0.5rem",
        width: "100%",
      }}
    >
      <Typography sx={{ whiteSpace: "nowrap" }}>{`${field}:`}</Typography>
      <Typography
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        fontWeight={600}
      >
        {value ? value : "--"}
      </Typography>
    </Box>
  );
}
