import { Box } from "@mui/material";
import { useActionables } from "./actionables";
import DialogUncombineShipment from "./DialogUncombineShipment";
import DialogConfirmForShipments from "./DialogConfirmForShipments";
import { useShipmentDetails } from "../../context/ShipmentDetailsContext";
import { useEffect, useState } from "react";
import DialogAppointmentTime from "./DialogAppointmentTime";
import DialogCandidateCarrier from "./DialogCandidateCarrier";
import DialogAskForPendingShipments from "./DialogAskForPendingShipments";

export function ShipmentActions() {
  const { 
    shipment,
    uncombine,
    pendingShipment,
    setShipmentAsPendingAndWaiting,
    confirmShipment,
    approveShipment,
    denyShipment,
    setAskForPending,
    askForPending,
    invalidOrders,
    addCandidateCarrier,
  } = useShipmentDetails();

  const actionables = useActionables({
    onUncombineTapped: () => {
      setUncombineConfirmDialogVisilibity(true)
    },
    onToPendingTapped: () => {
      setToPendingConfirmDialogOpened(true)
    },

    onApproveTapped: () => {
      setApproveConfirmDialogOpened(true)
    },

    onDenyTapped: () => {
      setDenyConfirmDialogOpened(true)
    },

    onAppointmentRequired: () => {
      setOpenDialogAppointTime(true);
    },
    onCandidateCarrier: () => {
      setOpenDialogCandidateCarrier(true);
    },
  });

  useEffect(() => {
    if (askForPending) { 
      setOpenDialogAskForPending(true);
    } else {
      setOpenDialogAskForPending(false);
    }
  }, [askForPending]);

  const [isUncombineConfirmDialogOpened, setUncombineConfirmDialogVisilibity] =
    useState(false);
  const [openDialogAppointTime, setOpenDialogAppointTime] = useState(false);
  const [openDialogCandidateCarrier, setOpenDialogCandidateCarrier ] = useState(false);
  const [openDialogAskForPending, setOpenDialogAskForPending] = useState(false);

  const appointmentPickup = shipment.services?.needsAppointmentPickup;
  const appointmentStop = shipment.services?.needsAppointmentStop;

  const handlePendingShipment = () => {
    setShipmentAsPendingAndWaiting();
    setOpenDialogAskForPending(false);
  }
  
  const [isToPendingConfirmDialogOpened, setToPendingConfirmDialogOpened] = useState(false);
  const [isApproveConfirmDialogOpened, setApproveConfirmDialogOpened] = useState(false);
  const [isDenyConfirmDialogOpened, setDenyConfirmDialogOpened] = useState(false);

  const pendingShip = (rate) => {
    if (rate) {
      rate = String(rate).replace(/,/g, ''); 
      rate = parseFloat(rate);
    }
    setToPendingConfirmDialogOpened(false);
    pendingShipment({ shipmentId: shipment._id, rate: rate });
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {actionables
          .filter((action) => action.isShown)
          .map(({ Actionable, key }) => (
            <Actionable key={key} />
          ))}
      </Box>

      <DialogConfirmForShipments
        title={"Approve Shipment"}
        text={"Are you sure you want to approve this shipment?"}
        onConfirm={(e) => {
            setApproveConfirmDialogOpened(false);
            approveShipment();
        }}
        onClose={() => { setApproveConfirmDialogOpened(false) }}
        open={isApproveConfirmDialogOpened} />

      <DialogConfirmForShipments
        title={"Deny Shipment"}
        text={"Are you sure you want to deny and archive this shipment?"}
        onConfirm={(e) => {
            setDenyConfirmDialogOpened(false);
            denyShipment();
        }}
        onClose={() => { setDenyConfirmDialogOpened(false) }}
        open={isDenyConfirmDialogOpened} />

      <DialogUncombineShipment
        onConfirm={() => {
          setUncombineConfirmDialogVisilibity(false);
          uncombine({ shipmentId: shipment._id });
        }}
        onClose={() => {
          setUncombineConfirmDialogVisilibity(false);
        }}
        open={isUncombineConfirmDialogOpened}
      />
      <DialogConfirmForShipments
        title={"Add to Pending"}
        text={"Are you sure want to set this shipment as Pending?"}
        onConfirm={(rate) => { pendingShip(rate) }}
        onClose={() => { setToPendingConfirmDialogOpened(false) }}
        open={isToPendingConfirmDialogOpened}
        inputRate
      />
      <DialogAppointmentTime
        onConfirm={(selectedAppointmentTimePickup, selectedAppointmentTimeStop) => {
          setOpenDialogAppointTime(false);
          confirmShipment(null, null, null, null, selectedAppointmentTimePickup, selectedAppointmentTimeStop);
        }}
        onClose={() => {
          setOpenDialogAppointTime(false);
        }}
        open={openDialogAppointTime}
        appointmentPickup={appointmentPickup}
        appointmentStop={appointmentStop}
      />
      <DialogCandidateCarrier
        onConfirm={(rateCarrier, mcCarrier, etaCarrier, emptyOn) => {
          setOpenDialogCandidateCarrier(false);          
          addCandidateCarrier(rateCarrier, mcCarrier, etaCarrier, emptyOn)
        }}
        onClose={() => {
          setOpenDialogCandidateCarrier(false);
        }}
        open={openDialogCandidateCarrier}  
        shipmentId={shipment._id}      
        useFormParams={{ defaultValues: null }}
      />
      <DialogAskForPendingShipments
        open={openDialogAskForPending}
        title={"You can't Confirm this Shipment"}
        text={"There are some Orders below that need to be validated before. Would you like to set this Shipment as Pending?"}
        invalidOrders={invalidOrders}
        onIgnore={() => {
          confirmShipment(null, null, null, null, true);
          setAskForPending(false);
        }}
        onConfirm={(e) => {
          handlePendingShipment(e)
        }}
        onClose={() => { 
          setAskForPending(false);
        }}
      />
    </>
  );
}
