import { createContext, useEffect } from "react";
import { useCityForm } from "../hooks/useCityForm";
import { FormProvider } from "react-hook-form";
import { Box } from "@mui/material";

export const CityFormContext = createContext(null);

export function CityFormProvider({ children, ...props }) {
    const useFormParams = props.useFormParams;
    const onSave = props.onSave;
    const reset = props.reset || false;

    const {
        isLoading,
        form,
        handleSubmit,
        onSubmitHandler,
        setError,
        clearErrors,
        mapFields,
        fieldsAlternativeCity,
        removeAlternativeCity,
        appendAlternativeCity,
        moveAlternativeCity,
        ...rest
    } = useCityForm({ useFormParams, onSave, reset });

    useEffect(() => {
        if (useFormParams?.defaultValues) mapFields(useFormParams.defaultValues);
    }, [useFormParams]);

    return (
        <CityFormContext.Provider
            value={{
                isLoading,
                form,
                handleSubmit,
                onSubmitHandler,
                setError,
                clearErrors,
                mapFields,
                fieldsAlternativeCity,
                removeAlternativeCity,
                appendAlternativeCity,
                moveAlternativeCity,
                ...rest
            }}
        >
            <FormProvider {...form}>
                <Box
                    component="form"
                    autoComplete="off"
                    onSubmit={(event) => {
                        clearErrors();
                        handleSubmit(onSubmitHandler)(event);
                    }}
                    sx={props.sx}
                >
                    {children}
                </Box>
            </FormProvider>
        </CityFormContext.Provider>
    );
};