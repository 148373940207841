import { Helmet } from "react-helmet-async";
import { DataTable } from "src/components/datagrid";
import { useCityQueries } from "./hooks/useCityQueries";
import { CityColums, CityDetailComuns as CityDetailColumns } from "./components/CityColums";
import CustomToolbar from "src/components/datagrid/widgets/CustomToolbar";
import { Box, Button, Card, Container, Grid, LinearProgress, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { CityDetailsDrawer } from "./components/DetailsDrawer";

export function CitiesPage() {
    const { 
        isCitiesLoading, 
        cities, 
        getCities, 
        isCitiesRefetching 
    } = useCityQueries();

    const { cityId } = useParams();
    const navigate = useNavigate();

    useEffect(()=> {
        getCities()
    },[])

    return (
        <>
            <Helmet><title>Cities</title></Helmet>
            <Container
                maxWidth="false"
                sx={{ width: 'fit-content', maxWidth: '100%' }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            mb: 1,
                            textAlign: 'center',
                            display: 'flex'
                        }}
                    >
                        Cities
                    </Typography>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => navigate('/city/create')}
                        sx={{ mb: 1, textTransform: 'capitalize' }}
                    >
                        + Add City
                    </Button>
                </Box>
                <Grid container item xs={12} justifyContent={'center'}>
                    <Card>
                        <section className="dataTable">
                            <DataTable
                                onEditTapped={(value) => navigate(`/city/${value._id}/edit`)}
                                selectedItemId={cityId}
                                loading={isCitiesLoading || isCitiesRefetching}
                                loadData={cities ?? []}
                                columns={CityColums}
                                customDrawerComponent={CityDetailsDrawer}

                                className="datagrid"
                                pagination
                                autoHeight
                                density="compact"
                                disableDensitySelector
                                initState={{
                                    columns: {
                                        columnVisibilityModel: { id: false }
                                    }
                                }}
                                slots={{
                                    toolbar: () => <CustomToolbar columns={CityColums} rows={cities} title="City"/>,
                                    loadingOverlay: LinearProgress
                                }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        printOptions: { disableToolbarButton: true },
                                        quickFilterProps: { debounceMs: 500 },
                                        loadData: cities ?? [],
                                    }
                                }}
                                detailsDrawerTitle="City"
                                detailColumns={CityDetailColumns}
                                modelKey={'locations'}
                                modelGridSetup={{

                                }}
                            />
                        </section>
                    </Card>
                </Grid>

            </Container>
        </>
    );
}