import {  
  FormControl,  
  InputLabel,
  MenuItem,  
} from "@mui/material";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import "md-editor-rt/lib/style.css";
import { useTenant } from "src/hooks/useTenant";
import { FormSelect } from "src/components/form/FormSelect";

export function SelectTenant({ defaultValue }) {
  const { setValue } = useFormContext();
  const { tenants } = useTenant();    
  const [ selected, setSelected ] = useState(defaultValue)

  const handleChangeTenant = (event) => {    
    const selectedValues = event.target.value;    
    if (selectedValues.includes("all")) {
      if (selectedValues.length > tenants.length) {
        setValue("changeNote.tenants", [])
        setSelected([])
      } else {
        setValue("changeNote.tenants", tenants.map((ten) => ten._id));
        setSelected(tenants.map((ten) => ten._id))
      }
    } else {
      setValue("changeNote.tenants", selectedValues);
      setSelected(selectedValues)
    }
  };

  return (
    <FormControl>
      <InputLabel id="tenants">Tenants</InputLabel>
      <FormSelect
        label="Tenants"    
        name={"tenants"}   
        defaultValue={defaultValue}
        value={selected}
        onChange={handleChangeTenant}
        multiple
      >
        <MenuItem value="all">Select all Tenants</MenuItem>

        {tenants?.map((ten) => (
          <MenuItem key={ten?._id} value={ten?._id}>
            {ten?.code} - {ten?.title}
          </MenuItem>
        ))}
      </FormSelect>
    </FormControl>
  )
}