import { ContactPageOutlined, DescriptionOutlined, TimelapseRounded } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import { DetailsDrawer } from "src/components/drawer/DetailsDrawer";
import { CityDetails } from "./CityDetails";
import { AlternativeCitiesDetails } from "./AlternativeCitiesDetails";

const tabs = [
  {
    title: "Basic Info",
    icon: <DescriptionOutlined />,
  },
  {
    title: "Alternative Cities",
    icon: <ContactPageOutlined />,
  },
];

export function CityDetailsDrawer({
  title = "Testing",
  selectedItem: city,
  onVisibilityChanged,
  onEditTapped,
  columns,
}) {
  const [selectedTabIndex, setSelectedTabIndex] = useState("0");
  const onTabSelected = (event, tabIndex) => {
    setSelectedTabIndex(tabIndex);
  };

  return (
    <DetailsDrawer
      title={`City Details`}
      selectedItem={city}
      onVisibilityChanged={onVisibilityChanged}
      onEditTapped={onEditTapped}
      rootRoute={"cities"}
    >
      <TabContext value={selectedTabIndex}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
          }}
        >
          <CityDrawerTabs onChange={onTabSelected} />
        </Box>

        <TabPanel value={"0"}>
          <CityDetails city={city} />
        </TabPanel>

        <TabPanel value={"1"}>
          <AlternativeCitiesDetails alternative_cities={city.alternative_cities} />
        </TabPanel>
      </TabContext>
    </DetailsDrawer>
  );
}

function CityDrawerTabs({ onChange }) {
  return (
    <TabList
      onChange={onChange}
      variant="scrollable"
      scrollButtons={true}
      allowScrollButtonsMobile
    >
      {tabs.map((item, index) => {
        return (
          <Tab
            icon={item.icon}
            iconPosition="start"
            label={item.title}
            value={index.toString()}
            key={item.title}
          />
        );
      })}
    </TabList>
  );
}

export function valueGetter(value, caseEmptyValue = "--") {
  return value ? value : caseEmptyValue;
}
