import {
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import "md-editor-rt/lib/style.css";
import { FormSelect } from "src/components/form/FormSelect";
import { AllRoles } from "src/models/roles";

export function SelectRole({ defaultValue }) {
  const { setValue } = useFormContext();
  const [selected, setSelected] = useState(defaultValue)

  const handleChangeRole = (event) => {
    const selectedValues = event.target.value;
    if (selectedValues.includes("all")) {
      if (selectedValues.length > AllRoles.length) {
        setValue("changeNote.roles", [])
        setSelected([])
      } else {
        setValue("changeNote.roles", AllRoles.map((role) => role));
        setSelected(AllRoles.map((role) => role))
      }
    } else {
      setValue("changeNote.roles", selectedValues);
      setSelected(selectedValues)
    }
  };  

  return (
    <FormControl>
      <InputLabel id="role">Roles</InputLabel>
      <FormSelect
        label="Role"
        name={"roles"}
        defaultValue={defaultValue}
        value={selected}
        onChange={handleChangeRole}
        multiple
      >
        <MenuItem value="all">Select all Roles</MenuItem>

        {AllRoles?.map((role) => (
          <MenuItem key={role} value={role}>
            {role}
          </MenuItem>
        ))}
      </FormSelect>
    </FormControl>
  )
}