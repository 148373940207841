import React, { useEffect, useState } from "react";
import { useChangeNoteQueries } from "./hooks/useChangeNoteQueries";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Delete, Edit, NoteAdd } from "@mui/icons-material";
import { useAuth } from "src/hooks/useAuth";
import ReactMarkdown from "react-markdown";
import { ChangeNoteForm } from "./components/change-notes/ChangeNoteForm";

export function ChangeNotesPage() {
  const { session } = useAuth();
  const isAdmin = session.permissions.IsAdmin;
  const [isOpen, setIsOpen] = useState(false);
  const [changeNote, setChageNote] = useState(null);

  const {
    getChangeNotes,
    isLoadingChangeNotes,
    isRefetchingChangeNotes,
    changeNotes,
    isDeletingNote,
    handleDelete,
  } = useChangeNoteQueries();

  useEffect(() => {
    getChangeNotes();
  }, []);

  if (isLoadingChangeNotes || isRefetchingChangeNotes) {
    return <Container>
      <LinearProgress />
    </Container>
  }

  if (isOpen) {
    return <ChangeNoteForm
      onClose={() => {
        setIsOpen(false);
        setChageNote(null);
      }}
      open={isOpen}
      changeNote={changeNote}
    />
  }

  return (
    <Container>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"baseline"}
      >
        <Typography variant="h2" marginBottom={"20px"}>
          Change Notes
        </Typography>
        {isAdmin && (
          <Button
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <Typography>Add new change note</Typography>
            <Box width={"8px"} />
            <NoteAdd />
          </Button>
        )}
      </Box>
      {changeNotes && changeNotes.length > 0 ? (
        changeNotes
          .map((note) => (
            <React.Fragment key={note._id}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  justifyContent: "start",
                  marginBottom: "16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "40%",
                  }}
                >
                  <h2>{note.title}</h2>
                  <small>
                    {new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }).format(new Date(note.created.at))}
                  </small>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "60%",
                    justifyContent: "start",
                  }}
                >
                  <h3>{note.subtitle}</h3>
                  <ReactMarkdown>{note.content}</ReactMarkdown>
                </Box>
              </Box>
              {isAdmin && (
                <>
                  <IconButton>
                    <Edit
                      onClick={() => {
                        setIsOpen(true);
                        setChageNote({ changeNote: note });
                      }}
                    />
                  </IconButton>
                  {isDeletingNote ? (
                    <CircularProgress />
                  ) : (
                    <IconButton onClick={() => handleDelete(note._id)}>
                      <Delete />
                    </IconButton>
                  )}
                </>
              )}
              <Divider />
            </React.Fragment>
          ))
      ) : (
        <p>Notes not found</p>
      )}
    </Container>
  );
}
