import React, { useEffect, useMemo, useState } from 'react';
import { Box, Card, Container, Grid, Typography } from '@mui/material';
import { VisibilitySwitch } from './components/VisibilitySwitch';
import { WeekCalendar } from './components/week-calendar/WeekCalendar';
import { useShipmentQueries } from './hooks/useShipmentQueries';
import { isEmpty } from 'lodash';
import { isEqual } from "date-fns";
import { backofficeShipmentColumns } from './constants/backofficeShipmentsColumns';
import { ShipmentsDataGrid } from './components/ShipmentsDataGrid';
import { useParams } from 'react-router-dom';
import { useTenant } from 'src/hooks/useTenant';

const initialPageState = {
    selectedCalendarDate: new Date().setHours(0, 0, 0, 0),
    isCalendarVisible: false,
}

export function ClosedShipments() {
    const [pageState, setPageState] = useState(initialPageState);
    const { shipmentId } = useParams();
    const { tenant } = useTenant();

    const {
        getClosedBackofficeShipments,
        closedBackofficeShipments,
        isLoadingClosedBackofficeShipments,
        isRefetchingClosedBackofficeShipments,
    } = useShipmentQueries();

    useEffect(() => {
        if (isEmpty(closedBackofficeShipments)) {
            getClosedBackofficeShipments("closed");
        }
    });

    const toggleCalendarVisibility = () => {
        setPageState({ ...pageState, isCalendarVisible: !pageState.isCalendarVisible });
    }

    const onSelectedDateChange = (selectedDay) => {
        setPageState({ ...pageState, selectedCalendarDate: selectedDay });
    }

    const filteredData = useMemo(() => {
        if (!Array.isArray(closedBackofficeShipments)) return [];

        if (pageState.isCalendarVisible && pageState.selectedCalendarDate) {
            return closedBackofficeShipments.filter((shipment) => {
                const deliveryDate = new Date(
                    shipment.stops[0].targetDeliveryLate
                ).setHours(0, 0, 0, 0);

                const shipDate = new Date(
                    shipment.pickups[0].targetShipLate
                ).setHours(0, 0, 0, 0);

                const currentDate = pageState.selectedCalendarDate;

                return isEqual(deliveryDate, currentDate) || isEqual(shipDate, currentDate);
            });
        }

        return closedBackofficeShipments;
    }, [closedBackofficeShipments, pageState.isCalendarVisible, pageState.selectedCalendarDate]);

    const columns = backofficeShipmentColumns(null, tenant);

    return <Container maxWidth="false">
        <Typography
            variant="h4"
            sx={{ mb: 1, mt: 3, textAlign: "center", display: "flex" }}
        >
            Closed Shipments
        </Typography>
        <Box sx={{ my: 4, display: { md: "none" } }} />
        <Grid container item xs={12}>
            <Card sx={{ p: 2 }}>
                <VisibilitySwitch
                    label="Filter by Day"
                    isVisible={!pageState.isCalendarVisible}
                    setVisibility={toggleCalendarVisibility}
                >
                    <WeekCalendar
                        startingDate={pageState.selectedCalendarDate}
                        onClickHandler={onSelectedDateChange}
                    />
                </VisibilitySwitch>
                <section className="dataTable">
                    <ShipmentsDataGrid
                        shipments={filteredData}
                        isLoadingData={isLoadingClosedBackofficeShipments || isRefetchingClosedBackofficeShipments}
                        columns={columns}
                        selectedItemId={shipmentId}
                        disableMultipleRowSelection={false}
                        getTreeDataPath={(row) => row.hierarchy || []}
                        defaultGroupingExpansionDepth={1}
                        isClosedShipment={true}
                    />
                </section>
            </Card>
        </Grid>
    </Container>
}