// Commented Roles are for the future
export const Roles = {
    Administrator: "Administrator",
    System: "System",
    //ShipmentManager: "ShipmentManager",
    WarehouseManager: "WarehouseManager",
    //OrderManager: "OrderManager",
    //DockManager: "DockManager",
    CustomerManager: "CustomerManager",
    SupplierManager: "SupplierManager",
    LtlManager: "LTLManager",
    //CarrierManager: "CarrierManager",
    LogisticsCoordinator: "LogisticsCoordinator",
    Contractor: "Contractor",
    BookAgent: "BookAgent",
    BookAgentManager: "BookAgentManager"
}

export const AllRoles = Object.values(Roles);

// Added function to validate the roles
export const Auth = (userSession) => {
    const role = userSession?.user?.role?.name ?? "user";
    return {
        IsAdmin: role === 'Administrator' || role === "System",
        IsBackOffice: role === 'Administrator' || role === "System" || role === "LogisticsCoordinator",
        IsWarehouseManager: role === 'WarehouseManager',
        IsLogisticsCoordinator: role === "LogisticsCoordinator",
        IsContractor: role === "Contractor",
        IsCustomerManager: role === "CustomerManager",
        IsShipmentManager: role === "ShipmentManager",
        IsSupplierManager: role === "SupplierManager",
        IsLTLManager: role === "LTLManager",
        isBookAgente: role === "BookAgent",
        isBookAgenteManager: role === "BookAgentManager"
    };
};
