import { useMutation, useQuery } from "react-query";
import { CityService } from "src/api/city.service";
import { useAuth } from "src/hooks/useAuth"

export const useCityQueries = () => {
    const { session } = useAuth();
    const service = new CityService(session.token, session.tenant._id);

    const { 
        refetch: getCities, 
        isLoading: isCitiesLoading, 
        data: cities 
    } = useQuery(
        'cities',
        { 
            queryFn: async () => { return service.getAll(); },
            enabled: false
        },
    );

    let cityId = null;
    const { 
        refetch: refetchCity, 
        isLoading: isCityLoading, 
        data: city 
    } = useQuery({
            queryFn: async () => service.getById(cityId),
            enabled: false
        });
    
    const getCity = (id) => {
        cityId = id;
        refetchCity(cityId);
    }

    const {
        mutate: create, 
        isLoading: isCreating 
    } = useMutation({
        mutationFn: async ({ city, onCreated, onFailed }) => 
        {
            service.create(city)
            .then(data => onCreated(data))
            .catch(error => onFailed(error.message))
        }
    });

    const { 
        mutate: update, 
        isLoading: isUpdating 
    } = useMutation({
        mutationFn: async ({ city, onCreated, onFailed }) => service
            .update(city)
            .then(data => onCreated(data))
            .catch(error => onFailed(error))
    })

    return {
        getCities,
        isCitiesLoading,
        cities,

        getCity,
        isCityLoading,
        city,

        create,
        isCreating,

        update,
        isUpdating
    }
}