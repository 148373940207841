import io from 'socket.io-client';
import config from "src/config";

let socket;

export const getSocketConnection = () => {
  if (!socket) {
    socket = io(config.apiUrl.replace('api/v1', ''));
    socket.on('connect', () => console.log(`Connected: ${socket.id}`));
    socket.on('disconnect', () => console.log('Disconnected from WebSocket'));
  }
  return socket;
};
