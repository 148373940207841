import { useMutation } from "react-query";
import { ShipmentConverterService } from "src/api/shipmentConverter.service";
import { useAuth } from "src/hooks/useAuth";
import { useSnackbar } from "src/hooks/useSnackbar";

export function useShipmentConverterQueries() {
  const { session } = useAuth();
  const { setSnackbarMessage } = useSnackbar();
  const service = new ShipmentConverterService(session.token, session.tenant._id);

  const {
    isLoading: isConverting,
    mutate: convertBOLToShipment,
    data: shipment,
  } = useMutation({
    mutationFn: ({ file }) => service.bol2Shipment(file),
    onError: (error) => {
      setSnackbarMessage({
        message: error.message,
        severity: "error",
      });
    },
  });

  return {
    isConverting,
    convertBOLToShipment,
    shipment
  };
}
