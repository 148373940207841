import React, { useState } from "react";
import {
  Box,
  Container,
  Button,
  Typography,
} from "@mui/material";
import { useAuth } from "src/hooks/useAuth";
import { supportDocsLinks, supportVideoTutorialLinks } from "./constants/support-links";
import { VideoTutorialsGrid } from "./components/VideoTutorialsGrid";
import { ArticleCard } from "./components/ArticleCard";
import { ContactUsCard } from "./components/ContactUsCard";
import { CardNews } from "./components/CardNews";
import { NavigateNext, Newspaper } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ContactUs } from "./contact-us/ContactUsDialog";
import { ChangeNoteForm } from "./components/change-notes/ChangeNoteForm";

export function SupportPage() {
  const { session } = useAuth();
  const links = supportDocsLinks(session);
  const tutorialsLinks = supportVideoTutorialLinks(session);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  if (isOpen) {
    return <ChangeNoteForm onClose={() => { setIsOpen(false); }} open={isOpen} />
  }

  return (
    <Container>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"baseline"}>
        <Typography variant="h2" fontWeight={"bold"}>Help Center</Typography>
      </Box>
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginTop: "24px",
        }}>
        <Typography variant="h3" fontWeight={"bold"}>What's New</Typography>
        <CardNews
          leading={<Newspaper />}
          trailing={<NavigateNext />}
          title="What's New and Updates"
          subtitle="Explore the latest features and recent system fixes."
          onClick={() => { navigate(`/changelog`); }} />
        <Typography variant="h3" fontWeight={"bold"}>Tutorials</Typography>
        <VideoTutorialsGrid videos={tutorialsLinks} />
        <Typography variant="h3" fontWeight={"bold"}>Articles</Typography>
        <ArticleCard links={links} />
        {/* <Box display={'flex'} justifyContent={"center"} width={'100%'}>
          <ContactUsCard />
        </Box> */}
        <ContactUs />
      </Box>     
    </Container>
  );
}