import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

export const useCityForm = (props) => {
    const useFormParams = props?.useFormParams;

    const form = useForm({ ...useFormParams });
    const {
        formState: { errors, isSubmitSuccessful },
        reset,
        handleSubmit,
        setError,
        clearErrors,
    } = form;

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isSubmitSuccessful) props.reset && reset();
    }, [isSubmitSuccessful, reset, errors]);

    const onSubmitHandler = async (values) => {
        setIsLoading(true);
        try {
            console.log(values)
            const data = await props.onSave(values);
            if (data.error) {
                updateErrors(data.errors, setError);
            }
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    const mapFields = (params, prefix = "") => {
        if (!params) return;
        Object.entries(params).forEach(([key, value]) => {
            if (!value) return;

            switch (key) {
                case "alternative_cities":
                    value.map((alternative_city) =>
                        fieldsAlternativeCity.some((field) => field.city === alternative_city.city)
                            ? null
                            : appendAlternativeCity(alternative_city)
                    );
                    return;
            }

            if (typeof value === "object")
                return mapFields(value, `${prefix + key}.`);

            form.setValue(prefix + key, value ?? false);
        });
    }

    const {
        fields: fieldsAlternativeCity,
        remove: removeAlternativeCity,
        append: appendAlternativeCity,
        move:   moveAlternativeCity,
    } = useFieldArray({ control: form.control, name: "alternative_cities" });

    return {
        isLoading,
        form,
        handleSubmit,
        onSubmitHandler,
        setError,
        clearErrors,

        mapFields,

        fieldsAlternativeCity,
        removeAlternativeCity,
        appendAlternativeCity,
        moveAlternativeCity,
    };
}

function updateErrors(errors, setError) {
    errors.forEach((error) => {
        setError(error.param, { type: "manual", message: error.msg });
    })
}