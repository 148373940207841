import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import { valueGetter } from "src/components/drawer/DetailsDrawer";

export function AlternativeCitiesDetails({ alternative_cities }) {
  console.log(alternative_cities)
  return (
    <Stack
      sx={{
        gap: 2,
      }}
    > 
      {!alternative_cities && 
        <Typography color={'GrayText'}> No contact available </Typography>
      }
      {alternative_cities &&
        alternative_cities.map((city, index) => {
          return (
            <Card
              key={index}
              variant="outlined"
              sx={{
                py: "0.5rem",
                px: "1rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "0.75rem",
              }}
            >
              <CardHeader
                sx={{
                  "&.MuiCardHeader-root": {
                    p: 0,
                  },
                }}
              />
              <CardContent
                sx={{
                  "&.MuiCardContent-root": {
                    p: 0,
                  },
                }}
              >
                <Box sx={{ width: '100%', display: 'flex', flexDirection: { xs: 'column', lg: 'column', xl: 'row' }, justifyContent: 'space-between' }}>
                  <FieldLine field={"City"} value={city.city} />
                  <FieldLine field={"State"} value={city.state} />
                  <FieldLine field={"Country"} value={city.ctry} />
                </Box>
              </CardContent>
            </Card>
          );
        })}
    </Stack>
  );
}

export function FieldLine({ field, value }) {
  return (
    <Box
      sx={{
        display: {
          md: "flex",
          xs: "block",
        },
        py: "0.25rem",
        gap: "0.5rem",
        width: "100%",
      }}
    >
      <Typography sx={{ whiteSpace: "nowrap" }}>{`${field}:`}</Typography>
      <Typography
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        fontWeight={600}
      >
        {value ? value : "--"}
      </Typography>
    </Box>
  );
}
