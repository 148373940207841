import { Box, Typography } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { ChangeNoteService } from "src/api/changeNote.service";
import { useAuth } from "src/hooks/useAuth";
import { useSnackbar } from "src/hooks/useSnackbar";

export function useChangeNoteQueries() {
    const { session } = useAuth();
    const service = new ChangeNoteService(session?.token, session?.tenant?._id);
    const { setSnackbarMessage } = useSnackbar();

    const { mutate: create, isLoading: isCreating } = useMutation({
        mutationFn: (values) =>
            service
                .create(values)
                .then((data) => {
                    setSnackbarMessage({
                        message: `Note successfully created!`,
                        severity: "success",
                    });

                    return data;
                }).catch((error) => {
                    setSnackbarMessage({
                        message: (
                            <Box>
                                <Typography mb={1}>Error creating note</Typography>
                                <Typography>{error.message}</Typography>
                            </Box>
                        ),
                        severity: "error",
                    });
                }),
    });

    const { mutate: update, isLoading: isUpdating } = useMutation({
        mutationFn: (values) =>
            service
                .update(session.user.id, values)
                .then((data) => {
                    setSnackbarMessage({
                        message: `Note successfully updated!`,
                        severity: "success",
                    });
                    getChangeNotes();

                    return data;
                }).catch((error) => {
                    setSnackbarMessage({
                        message: (
                            <Box>
                                <Typography mb={1}>Error updating note</Typography>
                                <Typography>{error.message}</Typography>
                            </Box>
                        ),
                        severity: "error",
                    });
                }),
    });

    const {
        isLoading: isLoadingChangeNotes,
        isRefetching: isRefetchingChangeNotes,
        data: changeNotes,
        refetch: getChangeNotes,
    } = useQuery('all-change-notes', {
        queryFn: async () => {
            return service.getAllNotes();
        },
        enabled: false
    });

    const {
        isLoading: isLoadingLastChangeNotes,
        isRefetching: isRefetchingLastChangeNotes,
        data: lastChangeNotes,
        refetch: getLastChangeNotes,
    } = useQuery('last-change-note', {
        queryFn: async () => {
            return service.getLastChangeNote();
        },
        enabled: false
    });

    const {
        isLoading: isDeletingNote,
        mutate: handleDelete,
    } = useMutation({
        mutationFn: (idNote) => {
            service
                .deleteNote(idNote)
                .then((data) => {
                    if (data) {
                        setSnackbarMessage({
                            message: 'Note successfully deleted!',
                            severity: 'success',
                        });
                        getChangeNotes();
                        return data;
                    }
                })
                .catch((error) => {
                    setSnackbarMessage({
                        message: (
                            <Box>
                                <Typography mb={1}>Failed to delete note.</Typography>
                                <Typography>{error.message}</Typography>
                            </Box>
                        ),
                        severity: "error",
                    });
                })
        }
    });

    return {
        create,
        isCreating,

        isLoadingChangeNotes,
        isRefetchingChangeNotes,
        changeNotes,
        getChangeNotes,

        isDeletingNote,
        handleDelete,

        update,
        isUpdating,

        isLoadingLastChangeNotes,
        isRefetchingLastChangeNotes,
        lastChangeNotes,
        getLastChangeNotes,
    }
}