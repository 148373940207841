import * as React from 'react';
import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { StandardDialog } from 'src/components/dialog/Dialog';
import { OrderDetails } from './backoffice/InvalidOrderDetails';

function DialogAskForPendingShipments(props) {

    return (
        <StandardDialog isOpen={props.open} onClose={props.onClose}>
            <Box sx={{ width: '100%' }}>
                <DialogTitle>{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.text}
                    </DialogContentText>
                </DialogContent>
                <Box sx={{ pl: 2, pr: 2 }}>
                    { props.invalidOrders && (
                        <OrderDetails 
                            invalidOrders={props.invalidOrders} />
                    ) }
                </Box>
                <DialogActions sx={{ pr: 6, pb: 4 }}>
                    <Button variant="outlined" onClick={props.onClose}>No, and close</Button>
                    <Button sx={{ mr: 2 }} variant="outlined" onClick={() => props.onConfirm()}>Yes, pend</Button>
                    <Button variant="contained" onClick={() => props.onIgnore()}>Ignore, and Proceed</Button>
                </DialogActions>
            </Box>
        </StandardDialog>
    );
}

export default DialogAskForPendingShipments;
